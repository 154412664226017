<template>
  <de-progress-spinner v-if="status === 'pending'" class="tw-w-1000 tw-h-1000" />

  <ul v-else-if="data" class="tw-grid xl:tw-gap-5 xl:tw-grid-cols-3">
    <li
      v-for="currency in data"
      :key="currency.id"
      class="tw-flex tw-items-center tw-p-2.5 tw-cursor-pointer hover:tw-bg-primary-600"
      @click="onCurrencyClick(currency.id, currency.shortName)"
    >
      <!--        https://github.com/nuxt/nuxt/issues/14766-->
      <img
        v-if="currency.logo"
        class="tw-w-750 tw-h-750 tw-mr-2.5"
        :src="currency.logo"
        :alt="currency.shortName"
        loading="lazy"
      />
      <div class="tw-flex tw-flex-col">
        <span class="heading-h5 xl:heading-h4">{{ currency.name }}</span>
        <span class="tw-text-primary-300 heading-h6">{{ currency.shortName }}</span>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useLazyAsyncData, useNuxtApp } from '#app';
import { AdditionalService } from '~/data/services/additional.service';
import DeProgressSpinner from '~/shared/components/lib/progress/DeProgressSpinner.vue';
import { UserService } from '~/data/services/user.service';
import { useUserStore } from '~/store/user';
import { useAuthStore } from '~/store/auth';
import type { AppCurrency } from '~/data/domain/currency';
import type { AppCurrencyModel } from '~/data/model/currency.model';

const emit = defineEmits(['currency-change']);

const nuxtApp = useNuxtApp();
const additionalRepository = AdditionalService(nuxtApp.$customFetch);

const { data, status } = await useLazyAsyncData(() => additionalRepository.getCurrencies(), {
  getCachedData(key) {
    const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];
    if (!data) {
      return;
    }

    return data as AppCurrencyModel[];
  },
});

const userRepository = UserService(nuxtApp.$customFetch);
const userStore = useUserStore();
const authStore = useAuthStore();

async function onCurrencyClick(currencyId: number, currencyShortName: AppCurrency) {
  userStore.updateUserCurrency(currencyId, currencyShortName);
  emit('currency-change');

  if (!authStore.isLoggedIn) return;
  await userRepository.changeCurrency(currencyId);
}
</script>
